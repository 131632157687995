/**
 * 轻部落指引页面
 */
import { Toast } from 'vant'

export default {
  name: 'guide-page',
  data () {
    return {
      logo: require('./../../../assets/images/qingbuluo-product-list/logo@2x.png'),
      slogan: require('./../../../assets/images/qingbuluo-product-list/beauty-life@2x.png'),
      guideBanner: require('./../../../assets/images/qingbuluo-product-list/guide-banner.png'),
      guideList: [
        { name1: '品牌', name2: '介绍', link: '' },
        { name1: '防伪', name2: '查询', link: 'security-search' },
        { name1: '领袖', name2: '风采', link: '' },
        { name1: '质检', name2: '报告', link: '' },
        { name1: '授权', name2: '查询', link: 'agent-search' },
        { name1: '系统', name2: '后台', link: 'home-index' },
      ]
    }
  },
  methods: {
    on2Entrance (item) {
      if (!item.link) {
        Toast('该功能尚未开放~')
        return
      }
      this.$router.push({ name: item.link, query: { ...this.$route.query } })
    }
  },
}